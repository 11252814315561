<template>
  <div>
    <OTitle>Spoof Call</OTitle>
    <CSocialLogin @login="onSocialLogin" v-if="!isLoggedIn"></CSocialLogin>
    <CFormFreeDirectCall/>
    <CAdNewsletter/>
    <o-j-l-links></o-j-l-links>
    <CShareThisInline/>
    <CFAQDirect></CFAQDirect>
    <CAdBitMarketPulse/>
  </div>
</template>

<script>
import OTitle from '../objects/OTitle'
import CFormFreeDirectCall from '../forms/CFormFreeDirectCall'
import { mapActions, mapMutations } from 'vuex'
import CShareThisInline from '@/components/CShareThisInline.vue'
import CFAQDirect from '@/components/CFAQDirect.vue'
import OJLLinks from '@/objects/OJLLinks.vue'
import CAdNewsletter from '@/components/CAdNewsletter.vue'
import CSocialLogin from '@/components/CSocialLogin.vue'
import CAdBitMarketPulse from '@/components/CAdBitMarketPulse.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageFreeDirectCall',
  components: {
    CAdBitMarketPulse,
    CSocialLogin,
    CAdNewsletter,
    OJLLinks,
    CFAQDirect,
    CShareThisInline,
    OTitle,
    CFormFreeDirectCall
  },
  methods: {
    ...mapActions(['fetchInfo']),
    ...mapMutations(['setAuth', 'clearPhones']),
    afterLogin () {
      this.clearPhones()
    },
    submit () {
      this.$callApi(api => api.login(this.form))
    },
    onSocialLogin ({ social, credential }) {
      if (social === 'google') {
        this.onGoogleLogin(credential)
      } else if (social === 'apple') {
        this.onAppleLogin(credential)
      }
    },
    async onGoogleLogin (token) {
      const api = await getApi()
      const result = await api.googleLogin({ token })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    },
    async onAppleLogin (code) {
      const api = await getApi()
      const result = await api.appleLogin({ code })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    }
  },
  mounted () {
    this.fetchInfo()
  }
}
</script>
